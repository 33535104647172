import { FC, ReactNode } from 'react';

import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import { Box, Stack, Typography } from '@mui/material';

import { convertDuration } from '@fllite-fe/shared/src/utils/convertDuration';

interface TripInfoCardItemProps {
	title: string;
	icon: ReactNode;
	text: ReactNode;
	subText: ReactNode;
	price?: boolean;
	duration?: number;
	noWrapText?: boolean;
	maxWidth?: boolean;
}

export const TripInfoCardItem: FC<TripInfoCardItemProps> = ({
	title,
	icon,
	text,
	subText,
	price,
	duration,
	noWrapText,
	maxWidth,
}) => (
	<Stack
		spacing={1}
		alignItems="flex-start"
		width={maxWidth ? { xs: '120px', mini: '160px' } : 'auto'}
	>
		<Typography
			fontSize={12}
			fontWeight={800}
			color="#8ACE57"
			letterSpacing="2px"
			textTransform="uppercase"
			{...(noWrapText ? { whiteSpace: 'nowrap' } : {})}
		>
			{title}
		</Typography>
		<Stack direction="row" spacing={1}>
			<Box mt="2px">{icon}</Box>
			<Box textAlign="left">
				<Typography
					fontSize={16}
					fontWeight={600}
					{...(price ? { color: 'primary' } : {})}
					{...(noWrapText ? { whiteSpace: 'nowrap' } : {})}
				>
					{text}
				</Typography>
				<Typography
					mt="4px"
					fontSize={12}
					{...(noWrapText ? { whiteSpace: 'nowrap' } : {})}
					fontWeight={400}
				>
					{subText}
				</Typography>
			</Box>
		</Stack>
		{duration && (
			<Stack direction="row" spacing={1}>
				<Box>
					<AccessTimeOutlinedIcon fontSize="small" color="success" />
				</Box>
				<Typography fontSize={12}>
					<b>{convertDuration(duration).hours}</b> h <b>{convertDuration(duration).minutes}</b> m
					flight
				</Typography>
			</Stack>
		)}
	</Stack>
);
