import { ReactElement } from 'react';

import { Dayjs } from 'dayjs';

import {
	ApiAdminadminAlertsGetCollectionQueryResult,
	ApiAdminadminAlertsIdGetQueryResult,
	ApiAdminaircraftDetailedTypesGetCollectionQueryResult,
	ApiAdminaircraftTypesGetCollectionQueryResult,
	ApiAdminairportsGetCollectionQueryResult,
	ApiAdminairportsIdGetQueryResult,
	ApiAdminairportTypesGetCollectionQueryResult,
	ApiAdminappSettingsIdGetQueryResult,
	ApiAdminarticleCategoriesGetCollectionQueryResult,
	ApiAdminarticlesGetCollectionQueryResult,
	ApiAdminbusinessCasesIdcharterOperatorProposalIdsGetQueryResult,
	ApiAdminbusinessCasesIdcreateCharterOperatorProposalsPutMutationBody,
	ApiAdminbusinessCasesIdGetQueryResult,
	ApiAdmincharterOperatorAircraftCalendarEntriesIdGetQueryResult,
	ApiAdmincharterOperatorsGetCollectionQueryResult,
	ApiAdmincharterOperatorsIdGetQueryResult,
	ApiAdmincityImagesGetCollectionQueryResult,
	ApiAdmincountriesGetCollectionQueryResult,
	ApiAdminemailDraftTemplateGetCollectionQueryResult,
	ApiAdminemailsGetCollectionQueryResult,
	ApiAdminemailsIdGetQueryResult,
	ApiAdminemailSignatureTemplateGetCollectionQueryResult,
	ApiAdminestimationsIdGetQueryResult,
	ApiAdminflightTypesGetCollectionQueryResult,
	ApiAdminflliteRevenuesGetCollectionQueryResult,
	ApiAdmininquiriesIdGetQueryResult,
	ApiAdmininquiryLegsGetCollection200One,
	ApiAdmininquiryLegsGetCollectionQueryResult,
	ApiAdmininterestsGetCollectionQueryResult,
	ApiAdminproposalsIdGetQueryResult,
	ApiAdminrealTimePricingProposalsIdGetQueryResult,
	ApiAdminstatesGetCollectionQueryResult,
	ApiAdminusersIdGetQueryResult,
	ApiAircraftDetailedTypesGetCollectionQueryResult,
	ApiAircraftTypesGetCollectionQueryResult,
	ApiAirportsGetCollectionQueryResult,
	ApiAppSettingsGetCollectionQueryResult,
	ApiArticleCategoriesGetCollectionQueryResult,
	ApiBusinessCasesIdcontractPdfGetQueryResult,
	ApiBusinessCasesIdGetQueryResult,
	ApiCharterOperatorProposalFormcharterOperatorProposalsIdGetQueryResult,
	ApiCharterOperatorProposalFormcharterOperatorProposalsPostMutationResult,
	ApiCharterOperatorProposalFormcharterOperatorsIdGetQueryResult,
	ApiCharterOperatorProposalFormcharterOperatorsIdPutMutationBody,
	ApiCharterOperatorProposalForminquiriescalculateRealTimePricingProposalsPostMutationResult,
	ApiCharterOperatorscheckInvitationTokenGetCollectionQueryResult,
	ApiCharterOperatorsIdGetQueryResult,
	ApiEstimationsIdGetQueryResult,
	ApiEstimationsPostMutationResult,
	ApiFboOriginsGetCollectionQueryResult,
	ApiFbosIdGetQueryResult,
	ApiFrequentPassengersGetCollectionQueryResult,
	ApiFrequentPassengersIdGetQueryResult,
	ApiInquiriescalculateRealTimePricingProposalsPostMutationResult,
	ApiInterestsGetCollectionQueryResult,
	ApiInvoicesIdGetQueryResult,
	ApiMeGetQueryResult,
	ApiMyBusinessCasesGetCollectionQueryResult,
	ApiOperatorBusinessCasesIdGetQueryResult,
	ApiOperatorProposalLegsIdPutMutationBody,
	ApiParticipantsIdGetQueryResult,
	ApiPetsIdGetQueryResult,
	ApiPreMadeFlightsGetCollectionQueryResult,
	ApiPublishedFlightsGetCollectionQueryResult,
	ApiRealTimePricingProposalGroupsIdGetQueryResult,
	ApiUserAirportsEntriesGetCollectionQueryResult,
	ArticleArticleCollectionArticleCategoryCollection,
	ExportJsonldAdminExportCollection,
} from '@fllite-fe/api';

type ArrayElementType<T> = T extends (infer U)[] ? U : never;

// client-section and frontfacing
export type BusinessCase = ApiBusinessCasesIdGetQueryResult; // business case detail
export type Trip = BusinessCase['trip'];
export type Proposal = Trip['proposal'];
export type ProposalLeg = Proposal['proposalLegs'][0];
export type Inquiry = BusinessCase['inquiry']; //GetInquiryItemQueryResult
export type InquiryDetailedTypes = Inquiry['aircraftDetailedTypes'];
export type InquiryLeg = Inquiry['inquiryLegs'][0];
export type Fbo = ApiFbosIdGetQueryResult;
export type Invoice = ApiInvoicesIdGetQueryResult;
export type MeUser = ApiMeGetQueryResult;
export type Participant = BusinessCase['participants'][0];
export type CharterOperator = BusinessCase['handledByCharterOperator'];
export type CancellationPolicies = CharterOperator['cancellationPolicies'];
export type Passenger = Participant['passengers'][0];
export type FrequentPassenger = ApiFrequentPassengersIdGetQueryResult;
export type FrequentPassengerFromCollection =
	ArrayElementType<ApiFrequentPassengersGetCollectionQueryResult>;
export type Pet = ApiPetsIdGetQueryResult;
export type Estimation = ApiEstimationsIdGetQueryResult;
export type CrewMember = Proposal['crewMembers'][0];
export type PublicFlight = ArrayElementType<ApiPublishedFlightsGetCollectionQueryResult>;
export type Rtp = ApiInquiriescalculateRealTimePricingProposalsPostMutationResult;
export type RtpCo =
	ApiCharterOperatorProposalForminquiriescalculateRealTimePricingProposalsPostMutationResult;
export type RtpProposal = Rtp['realTimePricingProposals'][0];
export type RtpCoProposal = RtpCo['realTimePricingProposals'][0];
export type RtpProposalLeg = RtpProposal['realTimePricingProposalLegs'][0];
export type RtpCoProposalLeg = RtpCoProposal['realTimePricingProposalLegs'][0];
export type PremadeFlight = ArrayElementType<ApiPreMadeFlightsGetCollectionQueryResult>;
export type AircraftDetailedType =
	ArrayElementType<ApiAircraftDetailedTypesGetCollectionQueryResult>;
export type Airport = ArrayElementType<ApiAirportsGetCollectionQueryResult>;
export type ParticipantGet = ApiParticipantsIdGetQueryResult;
export type ArticleInList = ArticleArticleCollectionArticleCategoryCollection;
export type CharterOperatorDetail = ApiAdmincharterOperatorsIdGetQueryResult;
export type AircraftList = CharterOperatorDetail['charterOperatorAircraftList'][0];
export type AircraftType = ArrayElementType<ApiAircraftTypesGetCollectionQueryResult>;
export type AircraftTypeDetailed =
	ArrayElementType<ApiAircraftDetailedTypesGetCollectionQueryResult>;
export type InquiryEstimation = Inquiry['estimation'];
export type EstimationPostResult = ApiEstimationsPostMutationResult;
export type CharterOperatorFromProposal =
	ApiCharterOperatorProposalFormcharterOperatorsIdGetQueryResult;
export type SeatType = AircraftList['seatTypeNumbers'][0]['seatType'];
export type ContractPdf = ApiBusinessCasesIdcontractPdfGetQueryResult;
export type Interest = ArrayElementType<ApiInterestsGetCollectionQueryResult>;
export type RtpGroup = ApiRealTimePricingProposalGroupsIdGetQueryResult;
export type ArticleCategory = ArrayElementType<ApiArticleCategoriesGetCollectionQueryResult>;
export type UserAirportsEntry = ArrayElementType<ApiUserAirportsEntriesGetCollectionQueryResult>;
export type AppSettings = ArrayElementType<ApiAppSettingsGetCollectionQueryResult>;

export const ArticleRootCategories = {
	blog: { id: 'blog', name: 'Blog' },
	how_tos: { id: 'how_tos', name: "How to's" },
} as const;

export type MyTripsBusinessCase = ArrayElementType<ApiMyBusinessCasesGetCollectionQueryResult>;
export type MyTripsTrip = MyTripsBusinessCase['trip'];
export type MyTripsProposal = MyTripsTrip['proposal'];
export type MyTripsProposalLeg = MyTripsProposal['proposalLegs'][0];
export type MyTripsInquiry = MyTripsBusinessCase['inquiry'];
export type MyTripsInquiryLeg = MyTripsInquiry['inquiryLegs'][0];
export type MyTripsParticipant = MyTripsBusinessCase['participants'][0];
export type MyTripsPassenger = MyTripsParticipant['passengers'][0];
export type FboOrigin = ArrayElementType<ApiFboOriginsGetCollectionQueryResult>;
export type SharedTripBusinessCase = PublicFlight;
export type ProposalLegUpdateBody = ApiOperatorProposalLegsIdPutMutationBody;

// operations
export type OpsCharterOperator = ApiCharterOperatorsIdGetQueryResult;
export type CharterOperatorProposal =
	| ApiCharterOperatorProposalFormcharterOperatorProposalsIdGetQueryResult
	| ApiCharterOperatorProposalFormcharterOperatorProposalsPostMutationResult;
export type CharterOperatorProposalLeg = CharterOperatorProposal['charterOperatorProposalLegs'][0];
export type OperatorBusinessCase = ApiOperatorBusinessCasesIdGetQueryResult;
export type OperatorPassenger = OperatorBusinessCase['participants'][0]['passengers'][0];
export type OpsCrewMember = OpsCharterOperator['crewMembers'][0];
export type OpsAircraftList = OpsCharterOperator['charterOperatorAircraftList'][0];
export type OpsCharterOperatorInviteCheck =
	ArrayElementType<ApiCharterOperatorscheckInvitationTokenGetCollectionQueryResult>;
export type UpdateCharterOperatorBody =
	ApiCharterOperatorProposalFormcharterOperatorsIdPutMutationBody;

// react-admin
export type AdminAlert = ApiAdminadminAlertsIdGetQueryResult;
export type AdminAirport = ApiAdminairportsIdGetQueryResult;
export type AdminAppSetting = ApiAdminappSettingsIdGetQueryResult;
export type AdminCharterOperator = ApiAdmincharterOperatorsIdGetQueryResult;
export type AdminCrewMember = AdminCharterOperator['crewMembers'][0];
export type AdminEmail = ApiAdminemailsIdGetQueryResult;
export type AdminExport = ExportJsonldAdminExportCollection;
export type AdminInquiry = ApiAdmininquiriesIdGetQueryResult;
export type AdminProposal = ApiAdminproposalsIdGetQueryResult;
export type AdminRealTimePricingProposal = ApiAdminrealTimePricingProposalsIdGetQueryResult;
export type AdminAircraftDetailedTypes =
	ArrayElementType<ApiAdminaircraftDetailedTypesGetCollectionQueryResult>;
export type AdminAircraftTypes = ArrayElementType<ApiAdminaircraftTypesGetCollectionQueryResult>;
export type AdminAirportTypes = ArrayElementType<ApiAdminairportTypesGetCollectionQueryResult>;
export type AdminAirportFromCollection = ArrayElementType<ApiAdminairportsGetCollectionQueryResult>;
export type AdminArticleCategory =
	ArrayElementType<ApiAdminarticleCategoriesGetCollectionQueryResult>;
export type AdminFlightType = ArrayElementType<ApiAdminflightTypesGetCollectionQueryResult>;
export type AdminCountry = ArrayElementType<ApiAdmincountriesGetCollectionQueryResult>;
export type AdminInterest = ArrayElementType<ApiAdmininterestsGetCollectionQueryResult>;
export type AdminArticle = ArrayElementType<ApiAdminarticlesGetCollectionQueryResult>;
export type AdminState = ArrayElementType<ApiAdminstatesGetCollectionQueryResult>;
export type AdminAlertFromCollection =
	ArrayElementType<ApiAdminadminAlertsGetCollectionQueryResult>;
export type AdminCityImage = ArrayElementType<ApiAdmincityImagesGetCollectionQueryResult>;
export type AdminRevenue = ArrayElementType<ApiAdminflliteRevenuesGetCollectionQueryResult>;
export type AdminCharterOperatorProposalIds =
	ApiAdminbusinessCasesIdcharterOperatorProposalIdsGetQueryResult;
export type AdminEmailDraftTemplate =
	ArrayElementType<ApiAdminemailDraftTemplateGetCollectionQueryResult>;
export type AdminEmailSignatureTemplate =
	ArrayElementType<ApiAdminemailSignatureTemplateGetCollectionQueryResult>;
export type AdminCharterOperatorProposal =
	ArrayElementType<ApiAdmincharterOperatorsGetCollectionQueryResult>;
export type AdminEmailFromCollection = ArrayElementType<ApiAdminemailsGetCollectionQueryResult>;
export type AdminCreateCharterOperatorProposalsBody =
	ApiAdminbusinessCasesIdcreateCharterOperatorProposalsPutMutationBody;
export type AdmincharterOperatorAircraftCalendar =
	ApiAdmincharterOperatorAircraftCalendarEntriesIdGetQueryResult;
export type AdminInquiryLegs = Exclude<
	ApiAdmininquiryLegsGetCollectionQueryResult,
	ApiAdmininquiryLegsGetCollection200One
>;

// Only for hack with ID
export type RaBusinessCase = ApiAdminbusinessCasesIdGetQueryResult & {
	id: string;
};

export type RaEstimation = ApiAdminestimationsIdGetQueryResult & {
	id: string;
};

export type RaProposal = ApiAdminproposalsIdGetQueryResult & {
	id: string;
};

export type RaCharterOperator = ApiAdmincharterOperatorsIdGetQueryResult & {
	id: string;
};

export type RaUser = ApiAdminusersIdGetQueryResult & {
	id: string;
};

export interface ExtendedInfoProps {
	component: ReactElement;
	handleClose: () => void;
	modalSize?: string;
	title?: string;
}

export type CustomDate = Date | string | Dayjs;
