import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { Button } from '../Button';
import { Icon } from '../Icon';
import { H4, Text } from '../Text';

export const CardContainer = styled('div', {
	shouldForwardProp: (prop) => prop !== 'withButton',
})<{ withButton?: boolean }>`
	width: 100%;
	background: white;
	border-radius: ${({ theme }) => theme.radius.boxRadius};
	display: flex;
	flex-direction: column;
	box-shadow: 0px 4px 20px 0px rgba(53, 53, 53, 0.12);

	@media (min-width: 870px) {
		display: grid;
		grid-template-columns: 440px auto;
	}

	${({ theme }) => theme.breakpoints.up('sm')} {
		min-height: ${({ withButton }) => (withButton ? '307px' : '250px')};
	}
`;

export const Gallery = styled('div', {
	shouldForwardProp: (prop) => prop !== 'withButton',
})<{ withButton?: boolean }>`
	flex-shrink: 0;
	border-radius: ${({ theme }) => theme.radius.smallBoxRadius};
	overflow: hidden;
	width: 100%;
	height: ${({ withButton }) => (withButton ? '307px' : '250px')};
	position: relative;
	padding: 5px;

	.image-gallery-thumbnail {
		border: none;
		cursor: pointer;
		opacity: 1;
		border-radius: 4px;
		overflow: hidden;
		width: 60px;
		transition: opacity ${({ theme }) => theme.transition.baseTransition};
	}

	.image-gallery-thumbnail.active,
	.image-gallery-thumbnail:hover,
	.image-gallery-thumbnail:focus {
		border: none;
		opacity: 1;
	}

	.image-gallery-thumbnails-wrapper {
		bottom: 50px;
		opacity: 0;
		transition: opacity ${({ theme }) => theme.transition.baseTransition};
	}

	.image-gallery-slide {
		height: ${({ withButton }) => (withButton ? '277px' : '222px')};

		& > div {
			height: 100%;
		}
	}

	.image-gallery-content.fullscreen {
		.image-gallery-slide {
			height: auto;
		}

		.image-gallery-thumbnail {
			width: 100px;
		}
		.image-gallery-thumbnails-wrapper {
			bottom: 70px;
		}
	}

	.image-gallery-fullscreen-button {
		opacity: 0;
		transition: opacity ${({ theme }) => theme.transition.baseTransition};
	}

	.image-gallery-image {
		height: 100%;
		object-fit: cover;
		border-radius: ${({ theme }) => theme.radius.smallBoxRadius};
	}

	${({ theme }) => theme.breakpoints.down('sm')} {
		height: ${({ withButton }) => (withButton ? '220px' : '232px')};

		.image-gallery-slide {
			height: ${({ withButton }) => (withButton ? '210px' : '222px')};

			& > div {
				height: 100%;
			}
		}
	}
`;

export const BrOnlyForDesktop = styled('br')`
	${({ theme }) => theme.breakpoints.down('sm')} {
		display: none;
	}
`;

interface ImageProps {
	grayScale?: boolean;
}
export const Image = styled('img')<ImageProps>`
	object-fit: cover;
	border-radius: ${({ theme }) => theme.radius.smallBoxRadius};
	flex-shrink: 0;
	width: 100%;
	height: 100%;
	filter: ${({ grayScale }) => (grayScale ? 'grayscale(100%)' : 'none')};
	opacity: ${({ grayScale }) => (grayScale ? '0.6' : '1')};
`;

export const PlaceholderImageCaption = styled('div')`
	border-radius: ${({ theme }) => theme.radius.smallBoxRadius};
	background: rgba(255, 255, 255, 0.75);
	padding: 5px 15px;
	display: none;
`;

export const PlaceholderImage = styled('div', {
	shouldForwardProp: (prop) => prop !== 'onImageClick',
})`
	width: 100%;
	height: 100%;
	background: linear-gradient(rgba(207, 218, 230, 0.7), rgba(207, 218, 230, 0.7)),
		url(/assets/aircraft-placeholder.webp);
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: ${({ theme }) => theme.radius.smallBoxRadius};

	:hover {
		${PlaceholderImageCaption} {
			display: block;
		}
	}
`;

export const GalleryBlur = styled('div')<{ isActive }>`
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	position: absolute;
	transition: backdrop-filter 0.2s;
	backdrop-filter: ${({ isActive }) =>
		isActive ? 'blur(20px) opacity(1)' : 'blur(0px) opacity(0)'};
`;

export const DataContainer = styled('div', {
	shouldForwardProp: (prop) => prop !== 'withButton',
})<{ withButton?: boolean }>`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: ${({ withButton }) => (withButton ? '25px 25px 20px' : '25px')};

	${({ theme }) => theme.breakpoints.down('sm')} {
		padding: 16px;
	}
`;

export const NamePriceRow = styled('div')`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	width: 100%;

	${({ theme }) => theme.breakpoints.down('sm')} {
		margin-bottom: 16px;
		display: block;
	}
`;

export const InfoBoxes = styled('div')`
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-column-gap: 11px;

	${({ theme }) => theme.breakpoints.down('sm')} {
		grid-column-gap: 4px;
	}
`;

export const InfoBox = styled('div')`
	width: 100%;
	padding: 8px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	height: 61px;
	background-color: rgba(140, 192, 255, 0.15);
	border-radius: ${({ theme }) => theme.radius.smallBoxRadius};
	text-align: center;

	${({ theme }) => theme.breakpoints.down('sm')} {
		min-height: 80px;
		padding: 12px 8px 8px;

		justify-content: flex-start;
	}
`;

export const Leg = styled('div', {
	shouldForwardProp: (prop) => prop !== 'isSelected',
})<{ isSelected: boolean }>`
	padding: 5px 17px;
	border-bottom: ${({ isSelected, theme }) =>
		isSelected ? `3px solid ${theme.colors.primary}` : '2px solid transparent'};
	cursor: pointer;
`;

export const LegSelector = styled('div')`
	position: absolute;
	top: 0px;
	margin-left: auto;
	margin-right: auto;
	left: 0;
	right: 0;
	max-width: 253px;
	background: #fff;
	border-radius: 0 0 4px 4px;
	display: flex;
	align-items: center;
	z-index: 1;
	padding: 0 5px;
	width: fit-content;
`;

export const ImageArrow = styled('div', {
	shouldForwardProp: (prop) => prop !== 'disabled',
})<{ disabled: boolean }>`
	height: 40px;
	width: 29px;
	display: flex;
	align-items: center;
	justify-content: center;
	filter: ${({ disabled }) => disabled && 'grayscale(100%)'};
	cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
	background-color: ${({ theme }) => theme.colors.baseFontColor};
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	z-index: 4;
`;

export const LeftImageArrow = styled(ImageArrow)`
	border-radius: 0 4px 4px 0;
	left: 0px;
`;

export const RightImageArrow = styled(ImageArrow)`
	border-radius: 4px 0 0 4px;
	right: 0px;
`;

export const FullScreenButton = styled('div')`
	opacity: 0;
	background: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 35px;
	height: 33px;
	transition: opacity ${({ theme }) => theme.transition.baseTransition};
	cursor: pointer;
	outline: none;
	position: absolute;
	z-index: 4;
	bottom: 10px;
	right: 10px;
	border-radius: 4px;

	&:hover {
		opacity: 1;
	}
`;

export const Price = styled('div')`
	text-align: left;

	${({ theme }) => theme.breakpoints.down('md')} {
		margin-top: 16px;
	}
`;

interface StyledTitleProps {
	isDisabled?: boolean;
}

export const StyledTitle = styled(Text)<StyledTitleProps>`
	display: flex;
	align-items: center;
	gap: 4px;
	font-size: 28px;
	${({ isDisabled }) => isDisabled && 'color: #97989a;'}

	${({ theme }) => theme.breakpoints.down('sm')} {
		font-size: 24px;
	}
`;

export const MainTitleContent = styled(Box)`
	display: inline-block;
	word-break: break-word;
	${({ theme }) => theme.breakpoints.up('sm')} {
		margin-right: 8px;
	}
`;

export const TitleContentWrapper = styled('div')`
	${({ theme }) => theme.breakpoints.up('sm')} {
		max-width: 60%;
	}
`;

export const StyledSubtitle = styled(Text)`
	color: #97989a;
`;

interface StyledPriceProps {
	isDisabled?: boolean;
}

export const PriceInfo = styled('span')<StyledPriceProps>`
	font-size: 12px;
	white-space: nowrap;
	display: inline-flex;
	${({ isDisabled }) => isDisabled && 'color: #97989a;'}

	${({ theme }) => theme.breakpoints.up('sm')} {
		display: inline;
	}
`;

export const StyledH4 = styled(H4)`
	font-weight: 400;
	margin-top: 0px;

	${({ theme }) => theme.breakpoints.down('sm')} {
		font-size: 18px;
	}
`;

export const PricePerSeatBox = styled('div')<{ alignLeft?: boolean }>`
	margin-top: 10px;

	${({ theme }) => theme.breakpoints.up('md')} {
		margin-top: 4px;
		display: flex;
		flex-direction: row;
		justify-content: ${({ alignLeft }) => (alignLeft ? 'flex-start' : 'flex-end')};
		align-items: ${({ alignLeft }) => (alignLeft ? 'center' : 'flex-start')};
	}
`;

export const MarginText = styled(Text)`
	margin-left: 8px;
	margin-top: 1.3px;

	${({ theme }) => theme.breakpoints.down('sm')} {
		font-size: 10px;
		margin-top: 3px;
		font-weight: 600;
	}
`;

export const LetterSpacedText = styled(Text)`
	letter-spacing: 0.3px;
	white-space: nowrap;
`;

export const ImageIcon = styled(Icon)`
	display: flex;
	align-items: center;
`;

interface SelectButtonProps {
	noTopMargin?: boolean;
}
export const SelectButton = styled(Button)<SelectButtonProps>`
	width: 100%;
	font-weight: 500;
	font-size: 16px;
	${({ noTopMargin }) => !noTopMargin && 'margin-top: 20px;'}
`;

export const BottomPart = styled('div')`
	display: flex;
	flex-direction: column;
`;
