import { FC } from 'react';

import { FlightInfo } from './LegsInfoCardSimple';

interface DaysLeftProps {
	inDays: number;
}

export const DaysLeft: FC<DaysLeftProps> = ({ inDays }) => {
	switch (true) {
		case inDays === 0:
			return <FlightInfo>Today</FlightInfo>;
		case inDays === 1:
			return <FlightInfo>Tomorrow</FlightInfo>;
		case inDays === -1:
			return <FlightInfo>Yesterday</FlightInfo>;
		case inDays < -1:
			return <FlightInfo>{`${Math.abs(inDays)} days ago`}</FlightInfo>;
		default:
			return <FlightInfo>in {inDays} days</FlightInfo>;
	}
};
