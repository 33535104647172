import { BusinessCase, MyTripsBusinessCase, SharedTripBusinessCase } from '../types';

export const getPricePerSeat = (
	businessCase: BusinessCase | MyTripsBusinessCase | SharedTripBusinessCase,
	isProposedTrip?: boolean,
	numberOfNewSeats: number = 0,
	calculateTargetPrice?: boolean,
) => {
	if (!businessCase || !businessCase.trip) {
		return null;
	}

	const tripPrice = businessCase.trip.proposal.priceInclMargin;
	const numberOfSeats = businessCase.totalNumberOfSeats;
	const numberOfOccupiedSeats = businessCase.numberOfOccupiedSeats;
	const targetPrice =
		businessCase?.trip?.proposal?.priceInclMargin / businessCase?.trip?.minimumNumberOfSeats;

	const pricePerSeat = isProposedTrip
		? Number(tripPrice / (numberOfOccupiedSeats + numberOfNewSeats))
		: Number(tripPrice / numberOfSeats);

	if (calculateTargetPrice) {
		const finalPrice = pricePerSeat < targetPrice ? pricePerSeat : targetPrice;
		return Math.round(finalPrice * 100) / 100;
	}

	return Math.round(pricePerSeat * 100) / 100;
};
