import { FC } from 'react';

import styled from '@emotion/styled';
import { Info } from '@mui/icons-material';
import { SxProps, Theme, Tooltip, useMediaQuery } from '@mui/material';

import { muiTheme } from '../../muiTheme';

const Em = styled('em')``;

interface Props {
	sx?: SxProps<Theme>;
	isDisabled?: boolean;
}

export const PriceTooltip: FC<Props> = ({ sx, isDisabled }) => {
	const isDesktop = useMediaQuery(muiTheme.breakpoints.up('md'));

	return (
		<Tooltip title={<PriceTooltipContent />} enterTouchDelay={isDesktop ? 700 : 0}>
			<Info
				fontSize="small"
				sx={{
					color: isDisabled ? '#97989a' : '#8cc0ff',
					marginBottom: '-5px',
					marginLeft: '5px',
					cursor: 'pointer',
					...(sx ? sx : {}),
				}}
			/>
		</Tooltip>
	);
};

export const PriceTooltipContent = () => (
	<>Estimated FET and SEG tax based on your request. Final amount will be calculated at checkout.</>
);
