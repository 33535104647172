import styled from '@emotion/styled';
import styledMap from 'styled-map';

interface SeparatorProps {
	dashed?: boolean;
	vertical?: boolean;
}

const bg = styledMap`
  default: ${({ theme }) => theme.colors.gray};
	dashed: repeating-linear-gradient(
			90deg,
			${({ theme }) => theme.colors.gray},
        ${({ theme }) => theme.colors.gray} 3px,
			transparent 3px,
			transparent 10px
		)
`;

const height = styledMap`
	default: 2px;
	vertical: 100%
`;

const width = styledMap`
	default: 100%;
	vertical: 2px
`;

export const SeparatorThicker = styled('div', {
	shouldForwardProp: (prop) => prop !== 'dashed' && prop !== 'vertical',
})<SeparatorProps>`
	height: ${height};
	width: ${width};
	background: ${bg};
`;
