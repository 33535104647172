import { BusinessCaseJsonldBusinessCaseDetailStatus as BusinessCaseStatus } from '@fllite-fe/api';
import { formatDateWithTz, isBefore } from '@fllite-fe/shared/src/utils/datetime';

import { BusinessCase } from '../types';

export const isTripHighlighted = (businessCase: BusinessCase) => {
	if (!businessCase?.trip) {
		return false;
	}
	const proposalLegs = businessCase.trip.proposal?.proposalLegs;
	const lastProposalLeg = proposalLegs?.[proposalLegs.length - 1];

	const isPast = isBefore(
		formatDateWithTz(lastProposalLeg?.departureAt, lastProposalLeg.departureTimeZone),
		new Date(),
	);
	return (
		(businessCase.status === BusinessCaseStatus.trip_booked ||
			businessCase.status === BusinessCaseStatus.paid ||
			businessCase.status === BusinessCaseStatus.finished ||
			businessCase.status === BusinessCaseStatus.awaiting_payment) &&
		!isPast
	);
};
