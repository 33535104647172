import { FC, ReactNode } from 'react';

import { DEFAULT_FET_TAX_RATE, DEFAULT_SEGMENT_TAX_PER_PERSON } from '../../const/taxes';
import { formatPrice } from '../../utils/formatters';
import { PriceTooltip } from './PriceInfo';
import { PriceInfo } from './ProposalCard.style';

interface Props {
	info?: ReactNode;
	price: number;
	isValid?: boolean;
	numberOfLegs: number;
	numberOfSeats: number;
	proposalLegs?: any;
	showAmount?: boolean;
}

export const getTaxes = (
	price: number,
	numberOfLegs: number,
	numberOfSeats: number,
	proposalLegs: any,
) => {
	const numberOfFuelStops =
		proposalLegs?.reduce((acc, item) => acc + Number(item?.fuelStopsCount ?? 0), 0) ?? 0;

	const fetTax = price * (DEFAULT_FET_TAX_RATE / 100);
	const segmentTax =
		numberOfSeats * (numberOfLegs + numberOfFuelStops) * DEFAULT_SEGMENT_TAX_PER_PERSON;

	return { fetTax, segmentTax, taxes: fetTax + segmentTax };
};

export const PriceTaxes: FC<Props> = ({
	info,
	price,
	isValid = true,
	numberOfLegs,
	numberOfSeats,
	proposalLegs,
	showAmount = true,
}) => {
	const { taxes } = getTaxes(price, numberOfLegs, numberOfSeats, proposalLegs);

	return (
		<PriceInfo isDisabled={!isValid}>
			excl. taxes {showAmount && formatPrice(taxes, 2)}
			{info ?? <PriceTooltip isDisabled={!isValid} />}
		</PriceInfo>
	);
};
