import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { Box, Stack } from '@mui/material';

import { BusinessCaseProposalStatus } from '@fllite-fe/api';

import { GqlBusinessCase, GqlBusinessCaseDetail, GqlDashboardBusinessCase } from '../gqlTypes';
import { ArticleRootCategories, BusinessCase, MyTripsBusinessCase } from '../types';

const LABEL_AUTOMATIC_PROPOSAL_REQUESTED = 'Automatic proposal requested';
const LABEL_MANUAL_PROPOSAL_REQUESTED = 'Manual proposal requested';

export const getTranslateForContractSignStatus = (contractSignStatus: string) => {
	switch (contractSignStatus) {
		case 'awaiting_contract_signing':
			return 'Waiting contract signature';
		case 'awaiting_charter_approval':
			return 'Confirming your flight';
		case 'contract_approved':
			return 'Ready to book';
		case 'contract_declined':
			return 'Declined';
		case 'contract_prepared':
			return 'Proposed';
		default:
			return contractSignStatus;
	}
};

export const getTranslateForProposalStatus = (proposalStatus: string) => {
	switch (proposalStatus) {
		case BusinessCaseProposalStatus.charter_operator_proposal_received:
			return 'CO proposal received';
		case BusinessCaseProposalStatus.proposal_sent_to_charter_operator:
			return 'Proposal sent to CO';
	}
};

type BusinessCaseType = BusinessCase | MyTripsBusinessCase | GqlBusinessCaseDetail;

export const translateBusinessCaseStatus = (
	businessCase?: BusinessCaseType,
	customStatus?: string,
	isAdmin?: boolean,
) => {
	const status = customStatus ?? businessCase?.status;
	const contractSignStatus = businessCase?.contractSignStatus;

	if (!status) {
		return null;
	}

	const isOwner = businessCase?.currentUserType === 'OWNER';
	const isUnknown = businessCase?.currentUserType === 'UNKNOWN';

	const everyonePaid = businessCase?.participants?.every(
		(participant) => participant.invoice?.paymentStatus === 'paid',
	);

	if (contractSignStatus && status === 'proposal_approved') {
		return getTranslateForContractSignStatus(contractSignStatus);
	}
	switch (status) {
		case 'inquiry_received':
			return 'Inquiry received';
		case 'automatic_proposal_requested':
			return LABEL_AUTOMATIC_PROPOSAL_REQUESTED;
		case 'manual_proposal_requested':
			return LABEL_MANUAL_PROPOSAL_REQUESTED;
		case 'proposal_created':
			return 'Proposal created';
		case 'proposal_sent':
			return 'Proposal sent';
		case 'proposal_approved':
			return 'Proposal approved';
		case 'trip_booked':
			return 'Trip approved';
		case 'awaiting_payment':
			return 'Payment confirmation';
		case 'paid':
			return !isAdmin && ((!isOwner && !everyonePaid) || isUnknown) ? 'Booked' : 'Paid';
		case 'canceled':
			return 'Canceled';
		case 'expired':
			return 'Expired';
		case 'fulfilled':
			return 'Fulfilled';
		default:
			return status;
	}
};

export const translateBusinessCaseStatusWithIcon = (
	businessCase?: BusinessCaseType | GqlDashboardBusinessCase | GqlBusinessCase,
	customStatus?: string,
	canceledByRole?: string,
	isAdmin?: boolean,
) => {
	const status = customStatus ?? businessCase?.status;
	const contractSignStatus = businessCase?.contractSignStatus;

	if (!status) {
		return null;
	}

	const isOwner = businessCase?.currentUserType === 'OWNER';
	const isUnknown = businessCase?.currentUserType === 'UNKNOWN';

	const everyonePaid = businessCase?.participants?.every(
		(participant) => participant.invoice?.paymentStatus === 'paid',
	);

	if (contractSignStatus && status === 'proposal_approved') {
		return getTranslateForContractSignStatus(contractSignStatus);
	}
	switch (status) {
		case 'inquiry_received':
			return 'Inquiry received';
		case 'proposal_created':
			return 'Proposal created';
		case 'automatic_proposal_requested':
			return LABEL_AUTOMATIC_PROPOSAL_REQUESTED;
		case 'manual_proposal_requested':
			return LABEL_MANUAL_PROPOSAL_REQUESTED;
		case 'proposal_sent':
			return 'Proposal sent';
		case 'proposal_approved':
			return (
				<Stack direction="row" alignItems="center" spacing={1}>
					<CheckCircleOutlinedIcon sx={{ fontSize: '16px' }} color="inherit" />
					<Box>Proposal approved</Box>
				</Stack>
			);
		case 'trip_booked':
			return (
				<Stack direction="row" alignItems="center" spacing={1}>
					<CheckCircleOutlinedIcon sx={{ fontSize: '16px' }} color="inherit" />
					<Box>Trip approved</Box>
				</Stack>
			);
		case 'awaiting_payment':
			return 'Payment confirmation';
		case 'paid':
			return (
				<Stack direction="row" alignItems="center" spacing={1}>
					<CheckCircleOutlinedIcon sx={{ fontSize: '16px' }} color="inherit" />
					<Box>{!isAdmin && ((!isOwner && !everyonePaid) || isUnknown) ? 'Booked' : 'Paid'}</Box>
				</Stack>
			);
		case 'canceled':
			if (canceledByRole === 'ROLE_API_USER') {
				return (
					<Stack direction="row" alignItems="center" spacing={1}>
						<CancelOutlinedIcon sx={{ fontSize: '16px' }} color="inherit" />
						<Box>Canceled by user</Box>
					</Stack>
				);
			}
			if (canceledByRole === 'ROLE_API_OPERATOR') {
				return (
					<Stack direction="row" alignItems="center" spacing={1}>
						<CancelOutlinedIcon sx={{ fontSize: '16px' }} color="inherit" />
						<Box>Canceled by operator</Box>
					</Stack>
				);
			}

			return (
				<Stack direction="row" alignItems="center" spacing={1}>
					<CancelOutlinedIcon sx={{ fontSize: '16px' }} color="inherit" />
					<Box>Canceled</Box>
				</Stack>
			);
		case 'expired':
			return (
				<Stack direction="row" alignItems="center" spacing={1}>
					<CancelOutlinedIcon sx={{ fontSize: '16px' }} color="inherit" />
					<Box>Expired</Box>
				</Stack>
			);
		case 'fulfilled':
			return (
				<Stack direction="row" alignItems="center" spacing={1}>
					<CheckCircleOutlinedIcon sx={{ fontSize: '16px' }} color="inherit" />
					<Box>Fulfilled</Box>
				</Stack>
			);
		default:
			return status;
	}
};

export const translateRootCategory = (rootCategory?: string) => {
	switch (rootCategory) {
		case ArticleRootCategories.how_tos.id:
			return ArticleRootCategories.how_tos.name;
		case ArticleRootCategories.blog.id:
			return ArticleRootCategories.blog.name;
		default:
			return rootCategory;
	}
};
