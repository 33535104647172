import { FC, useMemo } from 'react';

import styled from '@emotion/styled';
import { ModeEditOutline, PersonOutlineOutlined } from '@mui/icons-material';
import AirplanemodeActiveOutlinedIcon from '@mui/icons-material/AirplanemodeActiveOutlined';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import {
	Box,
	Button,
	Card,
	CardActionArea,
	CardActionAreaProps,
	Stack,
	Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { Icon } from '@fllite-fe/shared/src/components/Icon';
import { diff, formatDateWithTz } from '@fllite-fe/shared/src/utils/datetime';

import { BusinessCase, InquiryLeg } from '../../types';
import { translateBusinessCaseStatus } from '../../utils/commonTrans';
import { capitalizeWords } from '../../utils/fp';
import { isTripHighlighted } from '../../utils/isTripHighlighted';
import { Leg } from '../RequestAFlight/Map/types';
import { Text } from '../Text';
import { TripInfoCardItem } from '../TripInfoCard/TripInfoCardItem';
import { DaysLeft } from './DaysLeft';
import { StatusInfoIcon } from './StatusInfoIcon';

export const CardContainer = styled(Card)`
	background-color: transparent;
	box-shadow: none;
`;

type CardActionProps = CardActionAreaProps & {
	isBlue?: boolean;
	component?: string;
};
export const CardAction = styled(CardActionArea, {
	shouldForwardProp: (prop) => prop !== 'isBlue',
})<CardActionProps>`
	border: 0 none;
	width: 100%;
	background: ${({ isBlue }) =>
		isBlue
			? 'radial-gradient(circle at 355px 0, transparent 8px, #2077e0 9px, #004ba6), radial-gradient(circle at 355px 100%, transparent 8px, #2077e0 8px, #004ba6)'
			: 'radial-gradient(circle at 355px 0, rgba(204, 0, 0, 0) 10px, #fff 9px),radial-gradient(circle at 355px 100%, rgba(204, 0, 0, 0) 10px, #fff 9px)'};
	background-position: top, bottom;
	background-size: 100% 51%;
	background-repeat: no-repeat;
	box-shadow: 0px 4px 10px rgba(53, 53, 53, 0.1);
	border-radius: 8px;
	padding: 25px 20px;
	min-height: 128px;
	display: flex;
	align-items: center;
	${({ isBlue }) => (isBlue ? 'color: white;' : '')}
`;

const FlightTypeIcon = styled(Icon)`
	margin-top: 8px;
	display: inline-flex;
	scale: 1.25;
`;

const MoreLinkIcon = styled(Icon)`
	height: 20px;
	margin-left: 5px;
	top: 3px;
	position: relative;
	display: inline-flex;
`;

const FlightType = styled(Box)`
	background: linear-gradient(93.22deg, #53ae0e 5.2%, #5bd200 96.46%);
	border-radius: 14px;
	text-transform: uppercase;
	padding: 4px 12px;
	align-items: center;
	font-size: 10px;
	font-weight: 700;
	letter-spacing: 1px;
	color: white;
	align-self: flex-start;
	margin-left: 10px;
	white-space: nowrap;
`;

export const FlightInfo = styled(Box)`
	display: flex;
	background: #8cc0ff50;
	border-radius: 4px;
	padding: 6px 15px;
	align-items: center;
	font-size: 14px;
	font-weight: 500;
	letter-spacing: 1px;
	color: white;
	align-self: flex-start;
	white-space: nowrap;
	letter-spacing: 0;
	font-family: poppins, Roboto, 'Helvetica Neue';
`;

export const TextWithMaxWidth = styled('span')`
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 110px;
	display: block;
`;

export interface LegsInfoCardProps {
	mapLegs?: Leg[];
	firstLeg: InquiryLeg;
	returnLeg?: InquiryLeg;
	inverted?: boolean;
	flightTypeName: string;
	aircraftName?: string;
	numberOfSeats: number;
	isInShade?: boolean;
	isMultiLeg?: boolean;
	fixedWidth?: number;
	isOneWay?: boolean;
	isRoundTrip?: boolean;
	isUpcomingTrip?: boolean;
	businessCase?: BusinessCase;
	onEditRequestClick?: () => void;
}

export const LegsInfoCardSimple: FC<LegsInfoCardProps> = ({
	firstLeg,
	returnLeg,
	aircraftName,
	numberOfSeats,
	isMultiLeg,
	isOneWay,
	inverted,
	isRoundTrip,
	isUpcomingTrip,
	businessCase,
	onEditRequestClick,
}) => {
	const { push } = useRouter();

	const getBreakableText = (text: string) => text.replaceAll('/', ' / ');

	const departureCity = useMemo(
		() => getBreakableText(firstLeg?.departureAirport?.city || ''),
		[firstLeg],
	);
	const destinationCity = useMemo(
		() => getBreakableText(firstLeg?.destinationAirport?.city || ''),
		[firstLeg],
	);

	if (!firstLeg) return null;

	const inDays = diff(
		dayjs(firstLeg?.departureAt).startOf('day').format('YYYY-MM-DDTHH:mm'),
		dayjs(new Date()).startOf('day').format('YYYY-MM-DDTHH:mm'),
		'days',
	);

	const hasBookedDesign = isTripHighlighted(businessCase);

	return (
		<CardContainer>
			<CardAction
				isBlue={inverted}
				onClick={
					isUpcomingTrip
						? () => push(`/detail/${businessCase.id}`)
						: onEditRequestClick
							? onEditRequestClick
							: undefined
				}
				sx={isUpcomingTrip || onEditRequestClick ? {} : { cursor: 'unset' }}
			>
				<Stack direction="row" width="100%" spacing={2}>
					<Stack width="35%" direction="row" minWidth="350px">
						<Stack direction="row" alignItems="flex-start" spacing={2}>
							<Stack direction="row">
								<Box mt="12px">
									<LocationOnOutlinedIcon color="success" fontSize="medium" />
								</Box>
								<Stack>
									<Typography fontSize={32}>{firstLeg?.departureAirport.airportCode}</Typography>
									<Typography fontSize={12}>
										<TextWithMaxWidth>{capitalizeWords(departureCity)}</TextWithMaxWidth>

										{firstLeg?.departureAirport?.state?.code ??
											(firstLeg?.departureAirport as any)?.stateCode}
									</Typography>
								</Stack>
							</Stack>

							<Stack alignItems="center" pt="8px">
								{isMultiLeg || isRoundTrip ? (
									<FlightTypeIcon
										type={inverted ? 'aircraft-multiple-white' : 'aircraft-multiple-dark-blue'}
									/>
								) : (
									<FlightTypeIcon type={inverted ? 'aircraft-white-2' : 'aircraft-dark-blue'} />
								)}
							</Stack>
							<Stack>
								<Typography fontSize={32}>{firstLeg?.destinationAirport.airportCode}</Typography>
								<Typography fontSize={12}>
									<TextWithMaxWidth>{capitalizeWords(destinationCity)}</TextWithMaxWidth>

									{firstLeg?.destinationAirport?.state?.code ??
										(firstLeg?.destinationAirport as any)?.stateCode}
								</Typography>
							</Stack>
						</Stack>
						<FlightType>
							<Typography fontSize={10} fontWeight={700}>
								{isOneWay && 'One way'}
								{isRoundTrip && 'Round trip'}
								{isMultiLeg && 'Multi leg'}
							</Typography>
						</FlightType>
					</Stack>
					<Box width="65%">
						<Stack
							direction="row"
							spacing={3}
							justifyContent="space-between"
							paddingLeft={!returnLeg && !isUpcomingTrip && 8}
						>
							<TripInfoCardItem
								title="Departure"
								icon={<EventOutlinedIcon color="success" fontSize="small" />}
								noWrapText
								text={
									firstLeg?.departureAt
										? formatDateWithTz(
												firstLeg?.departureAt,
												firstLeg?.departureAirport.timeZone,
												'MMM D, YYYY',
											)
										: ''
								}
								subText={
									firstLeg?.departureAt && firstLeg?.departureAirport.timeZone
										? formatDateWithTz(
												firstLeg?.departureAt,
												firstLeg?.departureAirport.timeZone,
												'hh:mm a z',
											).toUpperCase()
										: ''
								}
							/>
							{returnLeg && (
								<TripInfoCardItem
									title="Return"
									icon={<EventOutlinedIcon color="success" fontSize="small" />}
									noWrapText
									text={
										returnLeg?.departureAt
											? formatDateWithTz(
													returnLeg?.departureAt,
													returnLeg?.departureAirport.timeZone,
													'MMM D, YYYY',
												)
											: ''
									}
									subText={
										returnLeg?.departureAt && returnLeg?.departureAirport.timeZone
											? formatDateWithTz(
													returnLeg?.departureAt,
													returnLeg?.departureAirport.timeZone,
													'hh:mm a z',
												).toUpperCase()
											: ''
									}
								/>
							)}
							<TripInfoCardItem
								title="Capacity"
								icon={<PersonOutlineOutlined color="success" fontSize="small" />}
								text={numberOfSeats}
								subText={`seat${numberOfSeats > 1 ? 's' : ''}`}
							/>
							{!isUpcomingTrip && aircraftName && (
								<TripInfoCardItem
									title="Aircraft"
									icon={
										<AirplanemodeActiveOutlinedIcon
											color="success"
											fontSize="small"
											sx={{ transform: 'rotate(90deg)' }}
										/>
									}
									text={aircraftName}
									subText=""
								/>
							)}
							{isUpcomingTrip && (
								<Stack spacing={1} justifyContent="space-between">
									<Stack
										spacing={1}
										alignItems="flex-start"
										direction="row"
										flexWrap="wrap"
										justifyContent="flex-end"
										gap="10px"
									>
										<FlightInfo>
											<StatusInfoIcon
												hasBookedDesign={hasBookedDesign}
												isExpired={businessCase?.status === 'expired'}
											/>
											<Typography fontSize={14} fontWeight={500} letterSpacing={0}>
												{translateBusinessCaseStatus(businessCase)}
											</Typography>
										</FlightInfo>
										<DaysLeft inDays={inDays} />
									</Stack>
									<Stack alignItems="flex-end">
										<Link href={`/detail/${businessCase.id}`} passHref>
											<Text white small medium>
												See trip details
												<MoreLinkIcon type="arrow-right-white" />
											</Text>
										</Link>
									</Stack>
								</Stack>
							)}
							{onEditRequestClick && (
								<Button variant="text" component="div" onClick={onEditRequestClick}>
									<Stack direction="row" spacing={1}>
										<ModeEditOutline color="primary" fontSize="small" />
										<Box whiteSpace="nowrap">Edit Request</Box>
									</Stack>{' '}
								</Button>
							)}
						</Stack>
					</Box>
				</Stack>
			</CardAction>
		</CardContainer>
	);
};
