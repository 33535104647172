import styled from '@emotion/styled';

export const StatusInfoIcon = styled('div', {
	shouldForwardProp: (prop) => prop !== 'hasBookedDesign' && prop !== 'isExpired',
})<{ hasBookedDesign?: boolean; isExpired?: boolean }>`
	width: 16px;
	height: 16px;
	border-radius: 50%;
	position: relative;
	margin-right: 8px;
	border: 2px solid #fff;
	background: ${({ hasBookedDesign, isExpired, theme }) =>
		isExpired
			? theme.colors.warning
			: hasBookedDesign
				? theme.colors.success
				: theme.colors.primary};
`;
