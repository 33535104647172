import { BusinessCaseContractSignStatus, BusinessCaseStatus } from '@fllite-fe/api';
import {
	BusinessCase,
	Participant,
	ProposalLeg,
	SharedTripBusinessCase,
} from '@fllite-fe/shared/src/types';

import { getPricePerSeat } from './getPricePerSeat';

export const getBCStatus = (
	data: BusinessCase | SharedTripBusinessCase,
	userParticipant?: Participant,
) => {
	const businessCase = data as BusinessCase;
	const bcStatus = businessCase.status;
	const currentUserType = businessCase.currentUserType;

	// single statuses
	const isInvitee = currentUserType === 'INVITEE';
	const isOwner = currentUserType === 'OWNER';
	const isParticipant = currentUserType === 'PARTICIPANT';
	const isUnknown = currentUserType === 'UNKNOWN';

	const isOwnerOrParticipant = isOwner || isParticipant;

	const isRequested =
		bcStatus === BusinessCaseStatus.inquiry_received ||
		bcStatus === BusinessCaseStatus.proposal_created ||
		bcStatus === BusinessCaseStatus.automatic_proposal_requested ||
		bcStatus === BusinessCaseStatus.manual_proposal_requested;
	const isProposal = bcStatus === BusinessCaseStatus.proposal_sent;
	const isCanceled = bcStatus === BusinessCaseStatus.canceled;
	const isExpired = bcStatus === BusinessCaseStatus.expired;
	const isFulfilled = bcStatus === BusinessCaseStatus.fulfilled;
	const isProposalApproved = bcStatus === BusinessCaseStatus.proposal_approved;
	const isBooked = bcStatus === BusinessCaseStatus.trip_booked;
	const isAwaitingPayment = bcStatus === BusinessCaseStatus.awaiting_payment;
	const isProposalRequested =
		bcStatus === BusinessCaseStatus.automatic_proposal_requested ||
		bcStatus === BusinessCaseStatus.manual_proposal_requested;
	const isInquiry = bcStatus === BusinessCaseStatus.inquiry_received;
	const isProposalSent = bcStatus === BusinessCaseStatus.proposal_sent;
	const isProposalCreated = bcStatus === BusinessCaseStatus.proposal_created;
	const isPaid = bcStatus === BusinessCaseStatus.paid;
	const isAwaitingCharterApproval =
		businessCase.contractSignStatus === BusinessCaseContractSignStatus.awaiting_charter_approval;
	const isInquiryOrProposal =
		bcStatus === BusinessCaseStatus.inquiry_received ||
		bcStatus === BusinessCaseStatus.automatic_proposal_requested ||
		bcStatus === BusinessCaseStatus.manual_proposal_requested ||
		bcStatus === BusinessCaseStatus.proposal_sent ||
		bcStatus === BusinessCaseStatus.proposal_created;

	const isSpecialRequest = businessCase.specialRequest && businessCase.specialRequest !== '';

	const proposal = businessCase?.trip?.proposal;
	const inquiry = businessCase?.inquiry;

	const tripProposalOrInquiry = proposal ? proposal : inquiry;

	const isRoundTrip = tripProposalOrInquiry?.flightType?.constantKey === 'FLIGHTTYPE_ROUND_TRIP';
	const isMultiLeg = tripProposalOrInquiry?.flightType?.constantKey === 'FLIGHTTYPE_MULTI_LEG';
	const isOneWay = tripProposalOrInquiry?.flightType?.constantKey === 'FLIGHTTYPE_ONE_WAY';

	// combine statuses
	const isAwaitingContractSignature =
		bcStatus === BusinessCaseStatus.proposal_approved &&
		businessCase.contractSignStatus === BusinessCaseContractSignStatus.awaiting_contract_signing &&
		isOwner;

	const isInquiryOrIsProposal =
		isInquiry || isProposalRequested || isProposalSent || isProposalCreated;

	const isPaidOrFulfilled =
		bcStatus === BusinessCaseStatus.paid || bcStatus === BusinessCaseStatus.fulfilled;

	const isDone = isCanceled || isExpired || isFulfilled;
	const isProposedTrip = businessCase.proposed;

	const isProposedTripInWaitingRoom =
		businessCase.proposed && isProposalApproved && !isAwaitingCharterApproval;

	const isWaitingRoom =
		businessCase.proposed &&
		isProposalApproved &&
		isOwnerOrParticipant &&
		!isAwaitingCharterApproval;

	const invoiceIsAwaiting = userParticipant?.invoice?.paymentStatus === 'awaiting_wire_transfer';

	const userInvoice = userParticipant?.invoice;
	const userPaid = userInvoice?.paymentStatus === 'paid';

	const seatsAvailable = businessCase.totalNumberOfSeats - businessCase.numberOfOccupiedSeats;

	const isDisabledAction = isExpired || isCanceled;
	const userPrice = userInvoice?.price ?? userParticipant?.price ?? '-';

	const showPay =
		(isBooked ||
			isAwaitingPayment ||
			isAwaitingCharterApproval ||
			(isParticipant && isProposalApproved)) &&
		!userPaid &&
		!invoiceIsAwaiting;

	const showWaitingRoomTarget =
		isParticipant && isProposedTrip && isProposalApproved && isWaitingRoom && !userInvoice;

	const pricePerSeat = getPricePerSeat(businessCase);
	const signContractIsDisabled = isSignContractIsDisabled(businessCase);
	const targetPrice =
		businessCase.trip?.proposal.priceInclMargin / businessCase.trip?.minimumNumberOfSeats;
	const priceApproved = userParticipant?.approvedPricePerSeat >= businessCase.seatPrice;
	const approvedHigherPrice =
		priceApproved && Math.round(userParticipant?.approvedPricePerSeat) > Math.round(targetPrice);

	return {
		isInvitee,
		isOwner,
		isParticipant,
		isUnknown,
		isOwnerOrParticipant,
		isRequested,
		isProposal,
		isCanceled,
		isExpired,
		isFulfilled,
		isProposalApproved,
		isBooked,
		isAwaitingPayment,
		isProposalRequested,
		isInquiry,
		isProposalSent,
		isProposalCreated,
		isPaid,
		isAwaitingCharterApproval,
		isSpecialRequest,
		isAwaitingContractSignature,
		isInquiryOrIsProposal,
		isPaidOrFulfilled,
		isDone,
		isWaitingRoom,
		isProposedTrip,
		isRoundTrip,
		isMultiLeg,
		isOneWay,
		invoiceIsAwaiting,
		userInvoice,
		userPaid,
		seatsAvailable,
		isDisabledAction,
		userPrice,
		showPay,
		showWaitingRoomTarget,
		pricePerSeat,
		signContractIsDisabled,
		isProposedTripInWaitingRoom,
		isInquiryOrProposal,
		targetPrice,
		priceApproved,
		approvedHigherPrice,
		...getExtendedBCStatus(
			businessCase,
			isOwner,
			isParticipant,
			isInvitee,
			isProposedTrip,
			userParticipant,
			isPaid,
		),
	};
};

const isSignContractIsDisabled = (businessCase: BusinessCase) =>
	!businessCase?.trip?.proposedTripBookable || businessCase?.participants?.length < 2;

const getExtendedBCStatus = (
	businessCase: BusinessCase,
	isOwner,
	isParticipant,
	isInvitee,
	isProposedTrip,
	userParticipant,
	isPaid,
) => {
	// extended resource
	const legsResource =
		(businessCase as BusinessCase)?.trip?.proposal?.proposalLegs ||
		(businessCase?.inquiry?.inquiryLegs as ProposalLeg[]);
	const aircraftName =
		(businessCase as BusinessCase)?.trip?.proposal?.proposalLegs[0].charterOperatorAircraft
			?.aircraftType?.name ||
		businessCase?.inquiry?.aircraftDetailedTypes?.map((item) => item.name as string)?.join(', ');
	const numberOfSeats =
		(businessCase as BusinessCase)?.trip?.proposal?.numberOfSeats ||
		businessCase?.inquiry?.numberOfSeats;
	const flightTypeName =
		(businessCase as BusinessCase)?.trip?.proposal?.flightType?.name ||
		businessCase?.inquiry?.flightType?.name;

	const roleOfUser = isInvitee
		? 'invitee'
		: isOwner
			? 'owner'
			: isParticipant
				? 'participant'
				: 'unknown';

	const showShareSection = isProposedTrip
		? ((isParticipant && userParticipant?.approvedPricePerSeat) || isOwner) && isPaid
		: isOwner;

	return {
		legsResource,
		aircraftName,
		numberOfSeats,
		flightTypeName,
		roleOfUser,
		showShareSection,
	};
};
