import { FC } from 'react';

import { ChairOutlined, RequestQuoteOutlined } from '@mui/icons-material';

import { formatPrice } from '../../utils/formatters';
import { MarginText, PricePerSeatBox } from './ProposalCard.style';

interface Props {
	price?: number;
	text?: string;
	alignLeft?: boolean;
	inverted?: boolean;
	disabled?: boolean;
	seatIcon?: boolean;
}

export const PricePerSeat: FC<Props> = ({
	price,
	text,
	alignLeft,
	inverted,
	disabled,
	seatIcon,
}) => (
	<PricePerSeatBox alignLeft={alignLeft}>
		{seatIcon ? (
			<ChairOutlined color={disabled ? 'disabled' : 'primary'} sx={{ fontSize: '16px' }} />
		) : (
			<RequestQuoteOutlined color={disabled ? 'disabled' : 'success'} sx={{ fontSize: '16px' }} />
		)}

		<MarginText subtext gray={!inverted} white={inverted} medium>
			<b>{formatPrice(price)}</b> {text ?? 'price per seat'}
		</MarginText>
	</PricePerSeatBox>
);
